import { useRouteLoaderData } from 'react-router';
import React from 'react';
import type { loader } from '~/routes/_app+/_layout';

type ErrorWithData<T> = {
  data: T;
  [key: string]: unknown;
};

export async function unwrap<T, U>(promise: Promise<T>, callback: (resolved: T) => Promise<U> | U) {
  try {
    const resolved = await promise;
    return await callback(resolved);
  } catch (error) {
    if (error && typeof error === 'object' && 'data' in error) {
      return await callback((error as ErrorWithData<T>).data);
    }
    throw error;
  }
}

export default function useTimeout(callback: () => void, delay: number) {
  const timeoutRef = React.useRef<number>();
  const savedCallback = React.useRef(callback);

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const tick = () => savedCallback.current();

    if (typeof delay === 'number') {
      timeoutRef.current = window.setTimeout(tick, delay);

      return () => window.clearTimeout(timeoutRef.current);
    }
  }, [delay]);

  return timeoutRef;
}

export const sleep = (ms: number = 3000) => new Promise((resolve) => setTimeout(resolve, ms));

export function jsonPrettify(payload: unknown) {
  return JSON.stringify(payload, undefined, 2);
}

export function getBeginningOfNextQuarter() {
  return new Date(
    new Date(new Date().getFullYear(), 0, 1).setMonth(
      Math.ceil((new Date().getMonth() + 1) / 3) * 3,
    ),
  );
}

export function useAppLoaderData() {
  const appLoaderData = useRouteLoaderData<typeof loader>('routes/_app+/_layout/index');

  if (!appLoaderData) {
    throw new Error('useAppLoaderData can only be called in the context of the app layout');
  }

  return appLoaderData;
}
